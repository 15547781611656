import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { auth, fs } from 'firebaseSetup'
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDoc, getDocs, collection, doc, setDoc, Timestamp } from "firebase/firestore"
import { buttonClickRecorded } from "hooks/EventGoAPI";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];


const afterLoginAction = () => {
  let eventView = {eventId: "", viewId: "", type: ""}
  let eventVStr = localStorage.getItem("login_redirect") || "{}"
  if (eventVStr != "{}"){
    eventView = JSON.parse(eventVStr)
    if (eventView.eventId != ""){
      if (eventView.type == "event"){
        window.location.href = "/event-order/" + eventView.eventId + "/" + eventView.viewId
      }
      else if (eventView.type == "tour"){
        window.location.href = "/tour-order/" + eventView.eventId + "/" + eventView.viewId
      }
      else{
        window.location.href = "/event-order/" + eventView.eventId + "/" + eventView.viewId
      }
    }
    else{
      window.location.href = "/account-orders"
    }
  }
  else{
    window.location.href = "/account-orders"
  }
}



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  })

  function loginWithGoogle(){
    var provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      'login_hint': 'user@example.com'
    });
    signInWithPopup(auth,provider).then((userVal) => {
      const userUID = userVal.user.uid || ""
      const userEmail = userVal.user.email || ""
      const userDisplayName = userVal.user.displayName || ""
      const userProfile = userVal.user.photoURL || ""
      let fn = ""
      let ln = ""
      if (userDisplayName.includes(" ")){
        fn = userDisplayName.split(" ")[0]
        ln = userDisplayName.split(" ")[1]
      }
      else {
        fn = userDisplayName
        ln = userDisplayName
      }
      localStorage.setItem("user_uid",userUID)
      let doccRef = doc(fs,"users",userUID)
      auth.currentUser?.getIdToken(true).then((idToken)=>{
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
        }
      })
      getDoc(doccRef).then((snapshot) => {
        if (snapshot.exists()){
          const userData = snapshot.data()
          const userDataString = JSON.stringify({"user": userData})
          localStorage.setItem("user",userDataString)

          if (userData.orgId != null && userData.orgId != ""){
            localStorage.setItem("org_id",userData.orgId)
            if (userData.orgName != null){
              localStorage.setItem("org_name",userData.orgName)
            }
            if (userData.profileURL != null){
              localStorage.setItem("user_profile",userData.profileURL)
            }
          }

          afterLoginAction()
        }
        else{
          //create user
          console.log('no user found')
          let userData = {
            'firstName':fn,
            'lastName':ln,
            'email':userEmail,
            'currentEvent': '',
            'lang': 'en',
            'userName': userDisplayName,
            'name': userDisplayName,
            'profileURL':userProfile,
            'isAnonymous': false,
            'timestamp': Timestamp.fromDate(new Date()),
          }
          setDoc(doc(fs,'users',userUID),userData).then((a)=>{
            localStorage.setItem("user_profile",userProfile)
            const userDataString = JSON.stringify({"user": userData})
            localStorage.setItem("user",userDataString)
            //go to account
            afterLoginAction()
          }).catch((e) => {
            alert(e.message)
          })
          
        }
      })
    
    }).catch((e) => {
      alert('login failed: ')
    })
  }

  async function loginCred(email:string, password:string){
    console.log("Logging as " + email + " " + password)
    signInWithEmailAndPassword(auth,email,password).then((userVal) => {
      const userUID = userVal.user.uid
      localStorage.setItem("user_uid",userUID)
      let doccRef = doc(fs,"users",userUID)
      auth.currentUser?.getIdToken(true).then((idToken)=>{
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
        }
      })
      getDoc(doccRef).then((snapshot) => {
        if (snapshot.exists()){
          const userData = snapshot.data()
          const userDataString = JSON.stringify({"user": userData})
          localStorage.setItem("user",userDataString)

          if (userData.orgId != null && userData.orgId != ""){
            localStorage.setItem("org_id",userData.orgId)
            if (userData.orgName != null){
              localStorage.setItem("org_name",userData.orgName)
            }
            if (userData.profileURL != null){
              localStorage.setItem("user_profile",userData.profileURL)
            }
          }

          afterLoginAction()
        }
        else{
          afterLoginAction()
        }
      })
      
    }).catch((e)=>{
      alert(e)
    })
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>EventGo | Login to your account</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                onClick={()=>{
                  buttonClickRecorded('SignIn - Google SignIn','')
                  loginWithGoogle()
                }}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.email = emailA
                  setUserInfo(userIn)
                }}
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" onChange={(e)=>{
                const passwordA = e.target.value
                let userIn = userInfo
                userIn.password = passwordA
                setUserInfo(userIn)

              }} className="mt-1" />
            </label>                     
          </form>
          <div className="flex justify-center items-center max-w-md mx-auto space-y-6">
          <ButtonPrimary className="sm:px-20"  onClick={()=>{
            buttonClickRecorded('SignIn - Regular SignIn','')
            loginCred(userInfo.email,userInfo.password)
          }}>Login</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
